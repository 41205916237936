import React from "react";
import PropTypes from 'prop-types';

const CallToAction = ({ placeholder, width, buttonText, color, size, disabled, onSubmit, loading, loadingComponent, ...rest }) => {

  let pix, piy, pbx, pby, bbg, bbgh, fsize;

  bbg = `bg-${color}`
  bbgh = `hover:bg-${color}-hover`

  // Set Size
  if (size === "lg") {
    pbx = `px-5`
    pby = `py-3`
    pix = `px-5`
    piy = `py-3`
    fsize= `text-lg`
  } else {
    // Default
    pbx = `px-4`
    pby = `py-2`
    pix = `px-4`
    piy = `py-2`
  }

  return (
    <div className="flex justify-center">
      <input
        placeholder={placeholder}
        className={`
          focus:outline-none
          rounded-l-full
          shadow-lg
          border
          focus:border-${color}
          transition
          ${width && width}
          ${pix && pix}
          ${piy && piy}
          ${fsize && fsize}
        `}
        {...rest}
      />
      <button
        className={`
          focus:outline-none
          font-medium
          rounded-r-full
          uppercase
          disabled:opacity-50
          transition
          text-white
          shadow-lg
          whitespace-nowrap
          ${bbg && bbg}
          ${bbgh && bbgh}
          ${pbx && pbx}
          ${pby && pby}
          ${fsize && fsize}
        `}
        onClick={() => onSubmit()}
        >
        {!loading ? buttonText : loadingComponent}
      </button>
    </div>
  )
}

export default CallToAction

CallToAction.defaultProps = {
  placeholder: "Your Email",
  width: "w-auto",
  buttonText: "Get Started",
  color: "primary",
  size: "md",
  disabled: false,
};

CallToAction.propTypes = {
  placeholder: PropTypes.string,
  // Tailwind Width class (https://tailwindcss.com/docs/width)
  width: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "accent"]),
  size: PropTypes.oneOf(["md", "lg"]),
  disabled: PropTypes.bool,
};