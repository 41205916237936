const getCurrentLanguage = ()  => {
  if(typeof window !== "undefined") {
    if(window.location.hash.substr(1) === "es" || window.location.hash.substr(1) === "en") {
      return window.location.hash.substr(1);
    }  
    else  {
      const userLang = navigator.language.substring(0,2);
      if(userLang == "es" || userLang == "en") {
        return userLang
      }
      else {
        //Default language
        return "en"
      }
    }
}
}
 
export default getCurrentLanguage