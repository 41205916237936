import React, { useState } from "react";
import PropTypes from 'prop-types';
import { CallToAction, CallToActionSelect } from "src/components/common";
import { send } from "emailjs-com"
import {sendMail} from "../../../../utils/sendMail";
import Spinner from "src/components/common/Spinner";

const Divider = ({ options, active, setActive, text, buttonText, placeholder }) => {
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [reply, setReply] = useState("")

  const onSubmit = async e => {
    setSending(true)

    try {
      await sendMail(
        {
          request_type: "Request DEMO de",
          from_name: reply,
          reply_to: reply,
          message: `Solicitud de DEMO para ${
            options.find(option => option.id === active).verboseName
          }`,
        },
        "contact"
      )
      setSent(true)
      setSending(false)
    } catch (error) {
      setSending(false)
    }
  }

  return (
    <div className="flex absolute bottom-0 z-0 left-0 right-0 px-2 md:px-2 mx-auto mb-4 justify-center z-10">
      <div className="inline-flex flex-col md:flex-row bg-secondary py-5 px-5 rounded-2xl sm:rounded-2xl md:rounded-full transform translate-y-1/2 items-center space-y-2 md:space-y-0">
        {!sent ? (
          <>
            <div className="flex items-center">
              <div className="text-white font-medium text-sm sm:text-lg">{text}</div>
              <CallToActionSelect
                options={options}
                active={active}
                setActive={setActive}
              />
            </div>
            <CallToAction
              loading={sending}
              loadingComponent={<Spinner size="1rem"/>}
              buttonText={buttonText}
              onChange={e => setReply(e.target.value)}
              onSubmit={onSubmit}
              placeholder={placeholder}
              width="w-3/5"
            />
          </>
        ) : (
          <p className="flex justify-center items-center font-medium px-2 font-semibold text-white inline">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-circle w-5 h-5 mx-2"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
            Recibimos tu solicitud, nos pondremos en contacto a la brevedad
          </p>
        )}
      </div>
    </div>
  )
}

export default Divider

Divider.propTypes = {
  options: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};