import React, { useState, useEffect }  from "react";
import PropTypes from 'prop-types';
import { Link } from "gatsby";
import { Button } from "src/components/common";
import LangSelector from "src/components/layout/LangSelector";
import MenuIcon from "src/components/layout/MenuIcon";
import getStaticContent from "../../../../utils/getStaticContent";
import getCurrentLanguage from "../../../../utils/getCurrentLanguage"; 
import scrollTo from 'gatsby-plugin-smoothscroll';

const NavLink = ({ link, scroll, type }) => (
  <Link className={`transition ${type === "default" && "hover:text-primary"} ${type === "light" && scroll ? "hover:text-primary" : "hover:text-gray-300"}`} to={link.href}>{link.title}</Link>
);

const NavBar = ({ type }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [lang, setLang] = useState("")
  const [links, setLinks] = useState([
    {
      title: "Products",
      href: "/#products"
    },
    {
      title: "Services",
      href: "/#services"
    },
    {
      title: "Capabilities",
      href: "/#capabilities"
    },
    {
      title: `${getStaticContent("about")}`,
      href: `/about${lang}`
    },
    {
      title: `${getStaticContent("jobs")}`,
      href: `https://sites.google.com/invera.com.ar/jobs/`
    },
  ]);

  useEffect(() => {

    const linksData = [
      {
        title: "Products",
        href: "/#products"
      },
      {
        title: "Services",
        href: "/#services"
      },
      {
        title: "Capabilities",
        href: "/#capabilities"
      },
      {
        title: `${getStaticContent("about")}`,
        href: `/about${lang}`
      },
      {
        title: `${getStaticContent("jobs")}`,
        href: `https://sites.google.com/invera.com.ar/jobs/`
      },
    ];

    setLinks(linksData)
  }, [lang])

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scroll) {
        setScroll(!scroll);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scroll]);
  

  return (
    <>
      {type != "light" ? (
        <>
          <nav className={`flex h-16 sticky top-0 transition-all duration-200 z-30 ${type === "defaultDark" && "bg-bgdark"} ${scroll || open ? "bg-bglight shadow-xl" : ""} ${scroll ? "lg:h-16" : "lg:h-20"}`}>
            <div className="container flex items-center sm:gap-7">
              {/* <div className="flex flex-grow lg:flex-grow-0 gap-5"> */}
              <div className="flex flex-grow sm:gap-5">
                <MenuIcon open={open} handleOpen={() => { setOpen(!open); console.log(open) }} />
                <Link to={`/#${getCurrentLanguage()}`} onClick={() => window.scrollTo(0,0)}><img className="transform scale-75 sm:scale-100 lg:-mt-3 " src="/img/logo-dark.svg" alt="Invera" /></Link>
              </div>
              {/* <div className="hidden lg:flex flex-grow gap-5">
                {links.map((link) => (<NavLink key={link.title} link={link} scroll={scroll} type={type}/>))}
              </div> */}
              <div className="flex gap-5 items-center justify-self-end">
                <div className="hidden lg:flex space-x-4">
                  <NavLink link={links[3]} />
                  <NavLink link={links[4]} />
                </div>
                <Button onClick={() => scrollTo(`#contact`, "center")}>{getStaticContent("contact")}</Button>
                <div className="hidden lg:flex">
                  <LangSelector type={type} setLang={setLang}/>
                </div>
              </div>
            </div>
          </nav>
          <nav className={`z-30 flex lg:hidden sticky top-16 bg-bgdark shadow-xl ${!open && "hidden"}`}>
            <div className="flex flex-col container max-w-min py-5">
              {/* {links.map((link) => (<div className="py-5 text-center text-xl" key={link.title}><NavLink link={link} /></div>))} */}
              <div className="py-5 text-center text-xl">
                <NavLink link={links[3]} />
              </div>
              <div className="py-5 text-center text-xl">
                <NavLink link={links[4]} />
              </div>
              <div className="py-5 text-center text-xl">
                <LangSelector type={type} open={open} setLang={setLang}/>
              </div>
            </div>
          </nav>
        </>
      ) : (
        <>
          <nav className={`flex h-16 sticky top-0 transition-all duration-200 z-30 ${scroll || open ? "bg-bglight shadow-xl" : ""} ${scroll ? "lg:h-16" : "lg:h-20"} ${!scroll && !open ? "text-white" : ""}`}>
            <div className="container flex items-center sm:gap-7">
              {/* <div className="flex flex-grow lg:flex-grow-0 gap-5"> */}
              <div className="flex flex-grow sm:gap-5">
                <MenuIcon open={open} handleOpen={() => { setOpen(!open); console.log(open) }} />
                <Link to={`/#${getCurrentLanguage()}`} onClick={() => window.scrollTo(0,0)}><img className="transform scale-75 sm:scale-100 lg:-mt-3" src={`${scroll || open ? "/img/logo-dark.svg" : "/img/logo-white.svg"}`} alt="Invera" /></Link>
              </div>
              {/* <div className="hidden lg:flex flex-grow gap-5">
                {links.map((link) => (<NavLink key={link.title} link={link} scroll={scroll} type={type} />))}
              </div> */}
              <div className="flex gap-5 items-center justify-self-end">
                <div className="hidden lg:flex space-x-4">
                  <NavLink link={links[3]} />
                  <NavLink link={links[4]} />
                </div>
                <Button onClick={() => scrollTo(`#contact`, "center")}>{getStaticContent("contact")}</Button>
                <div className="hidden lg:flex">
                  <LangSelector type={type} setLang={setLang}/>
                </div>
              </div>
            </div>
          </nav>
          <nav className={`z-30 flex lg:hidden sticky top-16 bg-bgdark shadow-xl ${!open && "hidden"}`}>
            <div className="flex flex-col container max-w-min py-5">
              {/* {links.map((link) => (<div className="py-5 text-center text-xl" key={link.title}><NavLink link={link} /></div>))} */}
              <div className="py-5 text-center text-xl">
                <NavLink link={links[3]} />
              </div>
              <div className="py-5 text-center text-xl">
                <NavLink link={links[4]} />
              </div>
              <div className="py-5 text-center text-xl">
               <LangSelector type={type} scroll={scroll} open={open} setLang={setLang}/>
              </div>
            </div>
          </nav>
        </>
      )}
    </>
  )
}

export default NavBar

NavBar.defaultProps = {
  type: "default"
};

NavBar.propTypes = {
  type: PropTypes.oneOf(["default", "defaultDark", "light"]),
};