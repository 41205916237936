import { send } from "emailjs-com"

export const sendMail = (toSend, type) => {
  const template = type === "contact" ? "template_y3zw9br" : "template_z30u9ks" 

  return send(
    "service_stju7il",
    template,
    toSend,
    "user_YNaLFPhbc1wbrsiY6SYfD"
  )
    .then(response => {
      console.log("SUCCESS!", response.status, response.text)
    })
    .catch(err => {
      console.log("FAILED...", err)
    })
}
