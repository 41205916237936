import React from "react";
import PropTypes from 'prop-types';

const MenuIcon = ({ open, handleOpen }) => {
  return (
    <button className="lg:hidden text-secondary w-10 h-10 relative focus:outline-none" onClick={handleOpen}>
      <span className="sr-only">Open Main Menu</span>
      <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span aria-hidden="true" className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${open ? "rotate-45" : "-translate-y-1.5"}`} />
        <span aria-hidden="true" className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${open && "opacity-0"}`} />
        <span aria-hidden="true" className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${open ? "-rotate-45" : "translate-y-1.5"}`} />
      </div>
    </button>   
  )
};

export default MenuIcon

MenuIcon.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired
};