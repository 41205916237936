import React, {useState} from "react";
import PropTypes from 'prop-types';

const Textarea = ({ name, placeholder, onChange, ...rest }) => {
  
  const [active, setActive] = useState(false);

  return (
    <div className="relative text-left">
      <label
        className={`transition absolute ${active ? "-top-3 px-1 label-focus text-primary left-7" : "left-5 text-lg top-3 cursor-text"}`} htmlFor={name}
        onClick={() => document.getElementById(name).focus()}
      >
        {placeholder}
      </label>
      <textarea
        id={name}
        name={name}
        placeholder=""
        onFocus={() => setActive(true)}
        onBlur={() => { if (!document.getElementById(name).value) { setActive(false) } }}
        onChange={onChange}
        className={`
          focus:outline-none
          rounded-3xl
          border
          focus:border-primary
          transition
          shadow-sm
          px-6
          py-3
          text-lg
          w-full
        `}
        {...rest}
      />
    </div>
  )
}

export default Textarea

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};