import React from "react";
import PropTypes from 'prop-types';

const Progress = ({ active, interval }) => {
  return ("")
}

export default Progress

Progress.propTypes = {
  active: PropTypes.string.isRequired,
  interval: PropTypes.number.isRequired,
};