import React, {useState} from "react";
import PropTypes from 'prop-types';
import getCurrentLanguage from "../../../../utils/getCurrentLanguage";

const languages = [
  {
    code: "en",
    href: "#en"
  }, {
    code: "es",
    href:"#es"
  },
  
];

const LangLink = ({ active, setActive, lang, type, scroll, open, setLang }) => (
  <>
    {type === "light" && !scroll && !open ? (
      <a className={`uppercase px-2 py-1.5 transition-all rounded-full ${active === lang.code ? "bg-white text-secondary" : "hover:text-secondary"}`} href={lang.href} onClick={() =>  {setActive(lang.code); setLang(lang.href)}}>
        {lang.code}
      </a>    
    ) : (
      <a className={`uppercase px-2 py-1.5 transition-all rounded-full ${active === lang.code ? "bg-secondary-bg" : "hover:text-primary"}`} href={lang.href} onClick={() => {setActive(lang.code); setLang(lang.href)}}>
        {lang.code}
      </a>   
    )}
  </>
);

const LangSelector = ({ type, scroll, open, setLang }) => {
  const [active, setActive] = useState(getCurrentLanguage());

  return (
    <div className={`flex shadow-2xl shadow-inner transform rounded-full gap-1 p-0.5 border ${type === "light" && !scroll && !open ? "bg-accent border-white" : "bg-bgdark border-transparent" }`}>
      {languages.map((lang) => (<LangLink key={lang.code} lang={lang} type={type} scroll={scroll} open={open}  active={active} setActive={setActive} setLang={setLang}/> ))}
    </div>
  )
};

export default LangSelector

LangSelector.defaultProps = {
  type: "default",
  scroll: false,
  open: false,
};

LangSelector.propTypes = {
  type: PropTypes.string,
  scroll: PropTypes.bool,
  open: PropTypes.bool,
};
