import React from "react";
import PropTypes from 'prop-types';

import Navbar from "src/components/layout/NavBar";
import Footer from "src/components/layout/Footer";

const Layout = ({ children, navBarType }) => {
  return (
    <div>
      <Navbar type={navBarType}/>
      {children}
      <Footer />
    </div>
  )
}

export default Layout

Layout.defaultProps = {
  navBarType: "default"
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  navBarType: PropTypes.oneOf(["default", "defaultDark", "light"]),
};