import React from "react"
import { Link } from "gatsby"
import {
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramLine,
  RiLinkedinBoxFill,
  RiYoutubeFill,
  RiMediumFill
} from "react-icons/ri"
import { CallToAction } from "src/components/common"
import getStaticContent from "../../../../utils/getStaticContent";

const links = [
  {
    title: "Products",
    href: "/#products",
  },
  {
    title: "Solutions",
    href: "/#solutions",
  },
  {
    title: "Capabilities",
    href: "/#capabilities",
  },
  {
    title: "About Us",
    href: "/about",
  },
]

const Footer = () => {
  return (
    <footer className="text-white transition duration-500 md:py-20 py-10 ease-in-out transform bg-secondary">
      <div className="container flex flex-col flex-wrap mx-auto md:items-center lg:items-start md:flex-row md:flex-no-wrap ">
        {/* <div> */}
        <div className="flex-grow text-center">
          <div className="inline-block">
            <Link to="/">
              <img src="/img/logo-white.svg" alt="Invera" />
            </Link>
          </div>
          <h1 className="mt-10 font-medium">{getStaticContent("follow")}</h1>
          <span className="mt-2 inline-flex justify-start sm:mb-4 space-x-2">
            <a
              href="https://www.facebook.com/inveraAr/"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiFacebookFill size="2.2rem" />
            </a>
            <a
              href="https://twitter.com/InveraArg"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiTwitterFill size="2.2rem" />
            </a>
            <a
              href="https://www.instagram.com/inveraarg/"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiInstagramLine size="2.2rem" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZSnjfApjOgS77BjO7Tqy4Q/"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiYoutubeFill size="2.2rem" />
            </a>
            <a
              href="https://www.linkedin.com/company/inveraarg/"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiLinkedinBoxFill size="2.2rem" />
            </a>
            <a
              href="https://medium.com/@invera"
              target="_blank"
              className="hover:text-primary-hover"
            >
              <RiMediumFill size="2.2rem" />
            </a>
          </span>
        </div>
        {/* <div className="flex flex-wrap w-40 flex-grow mt-8 -mb-10 text-left xl:pl-60 lg:pl-30 md:pl-24 md:mt-0 ">
          <div className="w-full px-2 lg:w-1/4 md:w-1/2">
            <h1 className="mb-4 text-sm font-medium tracking-widest text-white">
              {" "}
              <a href="/#products">Products</a>{" "}
            </h1>
            <nav className="mb-10 space-y-2 list-none">
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Onboarding
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Web Apps
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Reporting
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-2 lg:w-1/4 md:w-1/2">
            <h1 className="mb-4 text-sm font-medium  tracking-widest text-white  title-font">
              {" "}
              <a href="/#solutions">Services</a>{" "}
            </h1>
            <nav className="mb-10 space-y-2 list-none">
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Profiling
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Asset Allocation
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Algorithmic trading
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Financial Risk
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-2 lg:w-1/4 md:w-1/2">
            <h1 className="mb-4 text-sm font-medium tracking-widest text-white  title-font">
              {" "}
              <a href="/#solutions">Capabilities</a>
            </h1>
            <nav className="mb-10 space-y-2 list-none">
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Data science
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Financial Expertise
                </a>
              </li>
              <li>
                <a className=" mr-1 text-sm text-white  transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Software Development
                </a>
              </li>
            </nav>
          </div>
          <div className="w-full px-2 lg:w-1/4 md:w-1/2">
            <h1 className="mb-4 text-sm font-medium tracking-widest text-white title-font">
              {" "}
              <Link href="/about-us">About us</Link>
            </h1>
            <nav className="mb-10 space-y-2 list-none">
              <li>
                <a className=" mr-1 text-sm text-white transition duration-500 ease-in-out transform rounded-sm focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2">
                  Careers
                </a>
              </li>
            </nav>
          </div>
        </div> */}
      </div>
      <div className="container flex flex-wrap mt-12 mx-auto md:items-center lg:items-center md:flex-row md:flex-no-wrap">
        {/* <div className="flex-auto">
          <h1 className="mb-4 font-medium ">Suscribe our newsletter</h1>
          <div className="text-gray-600">
            <CallToAction size="md" />
          </div>
        </div> */}
        <div className="lg:mt-0 mt-2 text-gray-200 flex-auto">
          {/* <h1 className="lg:mt-0 mt-2 text-center lg:text-left"> */}
          {/* Get years on copyright date */}
          <h1 className="lg:mt-0 mt-2 text-center">
            Copyright  ©{new Date().getFullYear()}{" "}Invera | All rights reserved
          </h1>
        </div>
      </div>
    </footer>
  )
}

export default Footer