import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Input, Textarea } from "src/components/common"
import getStaticContent from "../../../../utils/getStaticContent"
import {sendMail} from "../../../../utils/sendMail";
import Spinner from "src/components/common/Spinner"

const Contact = ({ bg }) => {
  const [isSending, setSending] = useState(false)
  const [sent, setSent] = useState(false);

  const [toSend, setToSend] = useState({
    request_type : "Consulta de",
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  })

  const onSubmit = async e => {
    e.preventDefault()
    setSending(true)
    
    try {
      await sendMail(toSend, "contact")
      setSent(true)
      setSending(false)
    } catch (error) {
      setSending(false)
    }
  }

  const handleChange = e => {
    setToSend({ ...toSend, [e.target.name]: e.target.value })
  }

  return (
    <div className={`relative py-28 ${bg === "light" ? "bg-bglight" : "bg-bgdark"}`}>
      <div className="relative z-10 container text-center">
        <h2 className="text-4xl font-medium mb-3">{getStaticContent("title-contact")}</h2>
        <p className="text-lg text-primary">{getStaticContent("subtitle-contact")}</p>
        <div className="flex flex-col md:flex-row justify-center mt-10 md:space-x-10 text-2xl">
          <div className="flex items-center justify-center">
            <svg className="mr-2" width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect className="fill-primary-bg" x="4" y="1.24219" width="30" height="20.5234" rx="2" />
              <path className="fill-secondary" d="M27.493 5H2.50696C1.12796 5 0.00695801 6.122 0.00695801 7.5V22.5C0.00695801 23.878 1.12796 25 2.50696 25H27.494C28.873 25 29.994 23.878 29.994 22.5V7.5C29.993 6.122 28.873 5 27.493 5ZM28.993 22.5C28.993 23.327 28.32 24 27.493 24H2.50696C1.67996 24 1.00696 23.327 1.00696 22.5V7.5C1.00696 6.673 1.67996 6 2.50696 6H27.494C28.321 6 28.994 6.673 28.994 7.5L28.993 22.5Z" />
              <path className="fill-secondary" d="M26.9129 8.22765C26.7629 7.99565 26.4509 7.93066 26.2219 8.08066L16.2119 14.5877C15.4029 15.1417 14.0849 15.1417 13.2759 14.5877L3.77595 8.08766C3.55095 7.93266 3.23795 7.98966 3.07995 8.21766C2.92595 8.44566 2.98295 8.75666 3.21195 8.91266L12.7119 15.4127C13.2829 15.8027 14.0159 15.9977 14.7469 15.9977C15.4749 15.9977 16.2029 15.8047 16.7669 15.4197L26.7669 8.91966C26.9979 8.76866 27.0629 8.45865 26.9129 8.22765Z" />
              <path className="fill-secondary" d="M10.168 15.1197L3.16796 21.1197C2.95796 21.2997 2.93296 21.6157 3.11296 21.8247C3.21396 21.9407 3.35296 21.9997 3.49296 21.9997C3.60796 21.9997 3.72396 21.9597 3.81796 21.8787L10.818 15.8787C11.028 15.6987 11.053 15.3827 10.873 15.1737C10.692 14.9637 10.377 14.9397 10.168 15.1197Z" />
              <path className="fill-secondary" d="M19.819 15.1197C19.611 14.9397 19.295 14.9637 19.114 15.1737C18.935 15.3837 18.959 15.6987 19.169 15.8787L26.169 21.8787C26.262 21.9597 26.379 21.9997 26.493 21.9997C26.634 21.9997 26.773 21.9407 26.873 21.8247C27.052 21.6147 27.028 21.2997 26.818 21.1197L19.819 15.1197Z" />
            </svg>
            <a target="_blank" href={`mailto:${getStaticContent("email")}?subject=${getStaticContent("demo")}&body=${getStaticContent("email-body")}`} className="underline hover:text-primary">{getStaticContent("email")}</a>
          </div>
        </div>
        <div className="flex justify-center mt-8">
          <form
            id="form"
            onSubmit={onSubmit}
            className="w-full md:w-2/3 lg:w-1/2 xl:w-1/3 flex flex-col space-y-5"
          >
            <Input
              name="from_name"
              type="text"
              placeholder={getStaticContent("contact-name")}
              value={toSend.from_name}
              onChange={handleChange}
              disabled={sent}
              required
            />
            <Input
              name="reply_to"
              type="email"
              placeholder={getStaticContent("contact-mail")}
              value={toSend.reply_to}
              onChange={handleChange}
              disabled={sent}
              required
            />
            <Textarea
              name="message"
              placeholder={getStaticContent("contact-message")}
              value={toSend.message}
              onChange={handleChange}
              disabled={sent}
              required
            />
            {sent &&  
                  <div class="py-3 flex justify-center items-center font-medium px-2 bg-white rounded-3xl text-green-700 bg-green-100 border border-green-300 ">
                      <div slot="avatar">
                          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle w-5 h-5 mx-2">
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                              <polyline points="22 4 12 14.01 9 11.01"></polyline>
                          </svg>
                      </div>
                      <div class="text-lg text-left max-w-full flex-initial">
                      Recibimos tu mensaje correctamente
                      </div>                 
                  </div>}
                  
            <Button disabled={sent} type="submit" size="lg">
              {!isSending ? getStaticContent("contact-send") : <Spinner />}            
            </Button>
          </form>
        </div>
      </div>
       {/* <div className="absolute bottom-0 z-0 left-0 right-0 mx-auto text-center">
        <svg className="inline-block w-full lg:w-2/3 xl:w-1/2 h-auto" viewBox="0 0 913 531" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-accent-bg" d="M96.8077 531H897.744C897.744 531 961.803 332.129 827.388 286.344C708.901 245.984 625.465 333.39 569.186 269.885C512.906 206.38 533.993 64.4547 414.395 13.4677C248.805 -57.1321 -10.3657 164.222 0.320251 342.786C10.3508 510.725 96.8077 531 96.8077 531Z" />
        </svg>
      </div> */}
    </div>
  )
}

export default Contact

Contact.defaultProps = {
  bg: "light"
};

Contact.propTypes = {
  bg: PropTypes.oneOf(["light", "dark"]),
};
