import React from "react";
import PropTypes from 'prop-types';

const Button = ({ children, color, variant, size, disabled, fluid, onClick, current }) => {
  
  let bg, bgh, bor, borh, col, colh, sha, shah, px, py, fsize, width;

  // Set Variant
  if (variant === "outlined") {
    bg = `bg-transparent`
    bgh = `hover:bg-${color}`
    bor = `border-2 border-${color}`
    borh = `hover:border-0`
    col = `text-${color}`
    colh = `hover:text-white`
  } else {
    // Default
    bg = `bg-${color}`
    bgh = `hover:bg-${color}-hover`
    col = `text-white`
    sha = `shadow-lg`
    shah = `hover:shadow-sm`
  }

  // Set Size
  if (size === "lg") {
    px = `px-5`
    py = `py-3`
    fsize= `text-lg`
  } else if (size === "sm") {
    px = `px-2`
    py = `py-0`
    bor = `border border-${color}`
  } else {
    // Default
    px = `px-4`
    py = `py-2`   
  }

  //Set Fluid
  if (fluid) {
    width = `w-full`
  }

  return (
    <button
      className={`
        focus:outline-none
        font-medium
        rounded-full
        uppercase
        disabled:opacity-50
        transition
        ${bg && bg}
        ${bgh && bgh}
        ${bor && bor}
        ${borh && borh}
        ${col && col}
        ${colh && colh}
        ${sha && sha}
        ${shah && shah}
        ${px && px}
        ${py && py}
        ${fsize && fsize}
        ${width && width}
      `}
      onClick={onClick}
      disabled={!!disabled}
    >
      { children }
    </button>
  )
}

export default Button

Button.defaultProps = {
  color: "primary",
  variant: "default",
  size: "md",
  disabled: false,
  fluid: false,
  onClick: () => {},
};

Button.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "accent"]),
  variant: PropTypes.oneOf(["default", "outlined"]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  onClick: PropTypes.func,
  current: PropTypes.bool,
};