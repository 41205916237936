import React from "react";
import PropTypes from 'prop-types';
import { RiArrowDownSLine } from "react-icons/ri";

const CallToActionSelect = ({ options, active, setActive }) => {

  const handleChange = (e) => {
    setActive(parseInt(e.target.value));
  }

  return (
    <>
      <select onChange={handleChange} value={active} className="focus:outline-none focus:apperance-none block md:mx-2 cursor-pointer bg-secondary text-sm sm:text-lg font-bold text-white cta-select">
        {options.map(option => (
          <option key={option.id} value={option.id} selected={option.id === active}>
            {option.verboseName}
          </option>
        ))}
      </select>
    </>
  )
}

export default CallToActionSelect

CallToActionSelect.propTypes = {
  options: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  setActive: PropTypes.func.isRequired,
};