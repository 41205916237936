import React from 'react';
import PropTypes from 'prop-types';
import { FaSpinner } from 'react-icons/fa';

const Spinner = ({size}) => {
  return (
    <span className="flex justify-center items-center px-5" ><FaSpinner className="animate-spin text-center" size={size}/></span>
  )
};

export default Spinner;

Spinner.defaultProps = {
  size: "1.5rem"
};
  
Spinner.propTypes = {
  size: PropTypes.string,
};
